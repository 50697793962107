.live-stream-wrapper {
  position: relative;
  background-color: var(--background-secondary-color);
  height: 85vh;
  padding: 0px;
}

.live-stream-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stream-controls-wrapper {
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0px;
}

.stream-control {
  margin: 0px 5px;
}

.stream-control-button {
  background-color: var(--background-secondary-color);
  color: var(--portal-theme-primary);
  border: solid 2px var(--portal-theme-primary);
}

.stream-control-button-active {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-button:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-button-active:hover {
  background-color: var(--portal-theme-primary);
  color: var(--background-secondary-color);
}

.stream-control-icon {
  font-size: 24px;
}

.stream-info {
  display: flex;
  align-items: center;
  /* top: 15px;
  left: 15px; */
  background-color: var(--background-secondary-color);
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
}

.call-timer-section {
  display: flex;
  align-items: center;
  background-color: var(--background-secondary-color);
  color: #fff;
  padding: 2px 10px 2px 8px;
  font-size: 12px;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
}

.stream-users-info {
  display: flex;
  align-items: center;
  /* position: absolute;
  top: 15px;
  right: 15px; */
  background-color: var(--background-secondary-color);
  color: var(--portal-theme-primary);
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  border: solid 2px var(--portal-theme-primary);
  z-index: 1;
  cursor: pointer;
}

.stream-users-info span {
  color: white;
}

.live-stream-label {
  display: flex;
  align-items: center;
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: var(--background-secondary-color);
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  z-index: 1;
  border: solid 2px var(--portal-theme-primary);
}

.participant-control-icon {
  font-size: 18px;
}

.live-stream-comments {
  padding: 16px;
  height: 85vh;
  background-color: var(--sidebars-background-color);
  overflow-y: hidden;
  border-radius: 15px;
}

.live-stream-comments > .profile-comments {
  overflow-y: scroll;
}

.end-stream-btn {
  display: flex;
  align-items: center;
  bottom: 10px;
  left: 15px;
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
}

.settings-stream-btn {
  display: flex;
  align-items: center;
  bottom: 10px;
  right: 15px;
  color: white;
  padding: 2px 10px 2px 8px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
}

.live-stream-comments hr {
  margin: 3px auto;
}

.live-stream-logo {
  position: relative !important;
  z-index: 1111;
}

.join-stream-box {
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #6161614f;
  font-size: 16px;
  font-weight: 700;
  display: none;
  color: #fff;
}

.join-stream-box button {
  padding: 4px 16px;
}

.stream-video-available {
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #6161614f;
  font-size: 25px;
  font-weight: 600;
  display: flex;
}

.stream-video-available h2 {
  font-weight: 700;
  text-align: center;
  color: #fff;
  font-size: 25px;
}

.feed-image.position-relative:hover .join-stream-box {
  display: flex;
}

.live-stream-header {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px;
  top: 15px;
  z-index: 1;
}

.live-stream-loader {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

/* Flex container for videos */
#video-wrapper {
  width: 100%;
}

/* Style for each video container */
#video-container,
#video1-container {
  position: relative;
  background-color: black;
}

#player-controls {
  position: absolute;
  bottom: 16px;
  left: 0;
  width: 100%;
}

/* Custom controls */
#progress-bar-container {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  cursor: pointer;
}

#progress-bar {
  width: 0%;
  height: 100%;
  background-color: red;
}

.seek-on-icon-box {
  position: relative;
  width: 99%;
}

.seek-on-icon {
  position: absolute;
  left: 100%;
  top: -4px;
  width: 12px;
  height: 12px;
  background-color: red;
  border-radius: 50%;
  display: none;
}

#progress-bar-container:hover .seek-on-icon {
  display: block;
}

.play-pause-box {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.play-pause-box .play-pause svg {
  cursor: pointer;
  color: #fff;
  fill: #fff;
}

.playing-live-icon {
  height: 7px;
  width: 7px;
  background-color: #fff;
  border-radius: 50%;
}

.playing-live-icon.playing-live {
  background-color: red;
}

.live-stream-canvas {
  width: 100%;
  height: auto;
  display: block;
}

.full-screen svg {
  cursor: pointer;
  color: #fff;
  fill: #fff;
}

.hls-video-player {
  max-width: 100%;
}

.exit-video-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}

.remote-tiles-box {
  position: absolute;
  top: 50px;
  right: 15px;
  width: 200px;
  height: 80%;
  overflow: auto;
  z-index: 1;
}

.remote-tiles-box .co-host-video {
  height: 114px;
  border-radius: 10px;
  border: 2px solid #fff;
  cursor: pointer;
  position: relative;
  background-color: var(--background-primary-color);
}

.remote-tiles-box .co-host-video svg {
  font-size: 16px;
}

.remote-tiles-box .co-host-video button {
  padding: 2px;
  height: 25px;
}

.remote-tiles-box video {
  border-radius: 10px;
}

.remote-tiles-box .co-host-video:hover {
  border: 2px solid var(--button-outlined-color);
}

.attendee-user-name {
  z-index: 2;
  position: absolute;
  color: #fff;
  margin-left: 5px;
  margin-top: 5px;
  font-weight: bold;
  font-size: 13px;
}

.join-meeting-page video {
  width: 100%;
  border-radius: 20px;
}

.join-meeting-login h4 {
  margin: 0px 0px 8px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.235;
}

.join-meeting-login p {
  margin: 0px;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  color: rgb(99, 115, 129);
}

.use-guest-user {
  text-align: right;
  color: var(--portal-theme-primary);
  cursor: pointer;
}

.meeting-testing-controls {
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.meeting-testing-controls button {
  width: 50px;
  padding: 4px;
  border: 2px solid var(--portal-theme-primary);
  border-radius: 5px;
}

.meeting-start-date-time span {
  color: var(--portal-theme-primary);
}

.hidden-audio-player {
  z-index: 2;
  position: relative;
  text-align: center;
}

.hidden-audio-player button {
  margin-top: 20px;
  padding: 10px;
  outline: none;
  border-radius: 5px;
  background-color: var(--background-primary-color);
  color: #fff;
  border: 1px solid var(--portal-theme-primary);
}

.hidden-audio-player button:hover {
  background-color: var(--portal-theme-primary);
}

.start-meeting-box {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 3;
}

.start-meeting-box button {
  padding: 5px 20px;
  background-color: var(--background-primary-color);
  color: #fff;
  border: 1px solid var(--portal-theme-primary);
  border-radius: 5px;
}

.live-stream-timer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: var(--popup-background-color);
  padding: 10px;
  border-bottom-left-radius: 10px;
}

.live-stream-timer .timer-item {
  border: 1px solid #fff;
  margin: 3px;
  padding: 6px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  width: 80px;
}

.live-stream-timer .timer-label {
  color: var(--portal-theme-primary);
}

.live-stream-timer .timer-count {
  font-size: 30px;
  color: #fff;
}

.waiting-for-host {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: var(--popup-background-color);
  padding: 15px;
  border-bottom-left-radius: 10px;
  color: #fff;
  font-size: 16px;
}

.stream-video-available {
  align-items: center;
  background: #3131314f;
  display: flex;
  font-weight: 600;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 2;
}
.stream-video-available h2 {
  font-size: 35px;
}

@media (max-width: 820px) {
  .live-stream-wrapper {
    height: 55vh;
  }
  .live-stream-comments {
    height: 50vh;
    margin-top: 25px;
  }
}

@media (max-width: 571px) {
  .live-stream-wrapper {
    height: 45vh;
  }
  .live-stream-logo {
    max-width: 80%;
  }
}
